import { Routes, Route } from 'react-router';
import React, { lazy, Suspense,  useState } from 'react';
import './App.css';
import Nav_Bar from './components/NavBar/NavBar';
import ScrollToTop from './components/scrollTop';
import Footer from './components/Footer/Footer';
import DynamicLoader from './components/DynamicLoader/DynamicLoader';
import RegistrationPopUp from './components/RegistrationPopUp/RegistrationPopUp';
import UserfullInfo from './Pages/UsefullInfo/UserfullInfo';
import Tour from './Pages/Tour/Tour';
import Contact from './Pages/Contact/Contact';
import Visa from './Pages/Visa/Visa';


// Define lazy-loaded components
const Home = lazy(() => import('./Pages/Home/Home'));
const Organization = lazy(() => import('./Pages/Organization/Organization'));
const Registration = lazy(() => import('./Pages/Registration/Registratiion'));
const ConferenceVenu = lazy(() => import('./Pages/ConferenceVenu/ConferenceVenu'));
const Programme = lazy(() => import('./Pages/Programme/Programme'));
const Speaker = lazy(() => import('./Pages/Speaker/Speaker'));
const Accommodation = lazy(() => import('./Pages/Accommodation/Accommodation'));
const SocialProgramme = lazy(() => import('./Pages/SocialProgramme/SocialProgramme'));
const Abstract = lazy(() => import('./Pages/Abstract/Abstract'));
const SearchSpeakerDetails = lazy(() => import('./Pages/SearchSpeakerDetails/SearchSpeakerDetails.jsx'));
const Partner = lazy(() => import('./Pages/Sponsor/Partner.jsx'));
const Schedule = lazy(() => import('./Pages/Schedule/Schedule'));
const ParticipantsCertificate = lazy(() => import('./Pages/ParticipantsCertificate/ParticipantsCertificate.jsx'));

function App() {

  const [navigation,setNavigation] = useState(0) 
  const [toggleMenu, setToggleMenu] = useState(false);
   
  return (
    <div onClick={() => {
      setToggleMenu(false)
    }} id="home_page" className="App">
      <Nav_Bar toggleMenu={toggleMenu} setToggleMenu={setToggleMenu}  navigation={navigation} setNavigation={setNavigation}/>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Suspense fallback={<DynamicLoader/>}><Home setNavigation={setNavigation} /></Suspense>} />
        <Route path="/organization" element={<Suspense fallback={<DynamicLoader/>}><Organization setNavigation={setNavigation} /></Suspense>} />
        <Route path="/registration" element={<Suspense fallback={<DynamicLoader/>}><Registration setNavigation={setNavigation}/></Suspense>} />
        <Route path="/conferencevenue" element={<Suspense fallback={<DynamicLoader/>}><ConferenceVenu setNavigation={setNavigation}/></Suspense>} />
        <Route path="/programme" element={<Suspense fallback={<DynamicLoader/>}><Programme setNavigation={setNavigation} /></Suspense>} />
        <Route path="/speakers" element={<Suspense fallback={<DynamicLoader/>}><Speaker setNavigation={setNavigation}/></Suspense>} />
        <Route path="/accommodation" element={<Suspense fallback={<DynamicLoader/>}><Accommodation setNavigation={setNavigation}/></Suspense>} />
        <Route path="/socialprogramme" element={<Suspense fallback={<DynamicLoader/>}><SocialProgramme setNavigation={setNavigation}/></Suspense>} />
        <Route path="/abstracts" element={<Suspense fallback={<DynamicLoader/>}><Abstract setNavigation={setNavigation}/></Suspense>} />
        <Route path="/register" element={<Suspense fallback={<DynamicLoader/>}><RegistrationPopUp setNavigation={setNavigation}/></Suspense>} />
        <Route path="/usefulinformation" element={<Suspense fallback={<DynamicLoader/>}><UserfullInfo setNavigation={setNavigation}/></Suspense>} />
        <Route path="/tours" element={<Suspense fallback={<DynamicLoader/>}><Tour setNavigation={setNavigation}/></Suspense>} />
        <Route path="/contact" element={<Suspense fallback={<DynamicLoader/>}><Contact setNavigation={setNavigation}/></Suspense>} />
        <Route path="/visa" element={<Suspense fallback={<DynamicLoader/>}><Visa setNavigation={setNavigation}/></Suspense>} />
        <Route path="/registrationstatus" element={<Suspense fallback={<DynamicLoader/>}><SearchSpeakerDetails setNavigation={setNavigation}/></Suspense>} />
        <Route path="/partners&exhibitors" element={<Suspense fallback={<DynamicLoader/>}><Partner setNavigation={setNavigation}/></Suspense>} />
        <Route path="/conferenceschedule" element={<Suspense fallback={<DynamicLoader/>}><Schedule setNavigation={setNavigation}/></Suspense>} />
        <Route path="/participationcertificates" element={<Suspense fallback={<DynamicLoader/>}><ParticipantsCertificate setNavigation={setNavigation}/></Suspense>} />

      </Routes>
      
      <Footer />
    </div>
  );
}

export default App;





































// import { Routes,Route } from 'react-router';
// import Home from './Pages/Home/Home';
// import './App.css';
// import Nav_Bar from './components/NavBar/NavBar';
// import Organization from './Pages/Organization/Organization';
// import { useEffect } from 'react';

// import Footer from './components/Footer/Footer';
// import Weather from './components/Weather/Weather';
// // import HorizontalScroll from 'react-scroll-horizontal';
// import Registration from './Pages/Registration/Registratiion';
// import ConferenceVenu from './Pages/ConferenceVenu/ConferenceVenu';
// import Programme from './Pages/Programme/Programme';
// import ScrollToTop from './components/scrollTop';
// import Speaker from './Pages/Speaker/Speaker';
// import Accommodation from './Pages/Accommodation/Accommodation';
// import SocialProgramme from './Pages/SocialProgramme/SocialProgramme';
// import Abstract from './Pages/Abstract/Abstract';



// function App() {




// //  useEffect(()=>{
// //   localStorage.setItem('icomcNavigation',0)
   
// //  },[])

 


//   return (
//     <div id="home_page" className="App">
//       <Nav_Bar />
//       <ScrollToTop/>
//       <Routes>
//         <Route path="/" element={<Home/>}/>
//         <Route path="/organization" element={<Organization/>}/>
//         <Route path="/registration" element={<Registration/>}/>
//         <Route path="/conferencevenue" element={<ConferenceVenu/>}/>
//         <Route path="/programme" element={<Programme/>}/>
//         <Route path="/speakers" element={<Speaker/>}/>
//          <Route path="/accommodation" element={<Accommodation/>}/>
//          <Route path="/socialprogramme" element={<SocialProgramme/>}/>
//           <Route path="/abstract" element={<Abstract/>}/>
//       </Routes>
//       <Footer/>
//       {/* <div style={{ width: '100%', height: '75vh' }} ref={scrollContainerRef} onScroll={handleContainerScroll}>
//         <HorizontalScroll reverseScroll={true}>
//           <Element name="footer" ref={footerElementRef}>
//             {showFooter ? <Footer /> : null}
//           </Element>
//           <Weather />
//         </HorizontalScroll>
//       </div> */}
//     </div>
//   );
// }

// export default App;
