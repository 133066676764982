import React, { useEffect } from 'react'
import "./Visa.css"
import "../Organization/Organization.css"
import "../Registration/Registration.css"
import "../ConferenceVenu/ConferenceVenu.css"




const VisaComp = () => {
  return <div style={{
    marginTop:"2.7rem"
}}  className="ConferenceCoChairmen_comp_container">
       
       <div className="ConferenceCoChairmen_comp_container_header">
            <div style={{
              padding:"0.8rem",
              // paddingInline:"1.4rem",
            }} className="ConferenceCoChairmen_comp_container_logo">


<img style={{width:"55px"}} src="visa.png" alt="" />






            </div>
            <div className="ConferenceCoChairmen_comp_container_title">
              <h3>Visa Info</h3>
            </div>
        </div>


        <div className="conference_venu_text">
        <p>If you require a VISA to travel to India, you need to apply for a “Conference Visa”. To obtain a Conference Visa, you will require ‘approval’ from the Ministry of External Affairs. We will provide you with the ‘approval’. We will get in touch with you for the necessary information shortly <a style={{textDecoration:"underline"}} href="mailto:icomcvisainformation@gmail.com">(icomcvisainformation@gmail.com)</a> and submit it to the ministry for approval. This could be a few-month long procedure. You should go ahead with the VISA application procedure for a Conference Visa as soon we receive the Ministry's approval for you.</p>
        </div>
         
        
       
        
        

  </div>
}









const Visa = ({setNavigation}) => {

    useEffect(()=>{
      setNavigation(11)
        localStorage.setItem('icomcNavigation',11)
    },[])
    
  return (<div className="organization_page">
  <div className="organization_page_container">
    <h1>Visa</h1>
      <VisaComp/>
    </div>
    </div> 
  )
}

export default Visa