import React from "react"
import "./RegistrationPopUp.css"

const RegistrationPopUp = ({link}) => {
    
   
    return <div className="registration_pop_up_main_container">
        <iframe src={"https://www.force-india.com/iiser/index.php"} frameborder="0"></iframe>
    </div>



   }
   export default RegistrationPopUp