import React, { useEffect } from 'react'
import "./Contact.css"
import "../Organization/Organization.css"
import "../Registration/Registration.css"
import "../ConferenceVenu/ConferenceVenu.css"




const ContactComp = () => {
  return <div style={{
    marginTop:"2.7rem"
}}  className="ConferenceCoChairmen_comp_container">
        <div className="ConferenceCoChairmen_comp_container_header">
            <div style={{
              padding:"1.1rem",
              // paddingInline:"1.4rem",
            }} className="ConferenceCoChairmen_comp_container_logo">


<img style={{width:"40px"}} src="	https://cdn-icons-png.flaticon.com/512/3771/3771518.png" alt="" />

{/* <svg width="31" height="40" viewBox="0 0 31 40" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M14.1105 0.0357895C14.0011 0.0514221 13.618 0.106144 13.2506 0.153046C10.3035 0.551727 7.26265 2.29496 5.27707 4.7183C3.20551 7.25108 2.19709 10.3232 2.32998 13.669C2.40815 15.5139 2.66612 16.5614 3.47129 18.2421C4.33901 20.0635 5.40996 21.7286 11.1244 30.2024C14.5561 35.2836 14.5796 35.3227 15.2049 35.3227C15.846 35.3227 15.7521 35.44 20.5832 28.209C25.9692 20.1573 26.6884 18.9769 27.4467 17.0226C28.1424 15.2168 28.291 12.5902 27.8219 10.3232C26.6963 4.87465 22.0919 0.668983 16.5964 0.0748749C15.885 -0.0032959 14.5483 -0.0267487 14.1105 0.0357895ZM15.7365 5.20297C15.8538 5.28896 16.4635 6.1254 17.1045 7.07128L18.2615 8.78325L20.333 9.30701C21.9434 9.70569 22.4515 9.86985 22.6078 10.0106C23.0847 10.4561 23.1551 11.0112 22.7955 11.5662C22.6704 11.7538 22.0763 12.5433 21.4665 13.3172C20.6066 14.4195 20.3643 14.7791 20.3643 14.9589C20.3721 15.0839 20.419 15.8422 20.4816 16.6396C20.6379 18.6799 20.6301 19.1254 20.4425 19.4381C20.2392 19.7664 19.6686 20.0322 19.3168 19.9697C19.1839 19.9462 18.2537 19.6257 17.2452 19.2583C16.2446 18.8909 15.3769 18.5939 15.3222 18.5939C15.2753 18.5939 14.3685 18.9456 13.3054 19.3756C12.25 19.8055 11.3041 20.1573 11.2025 20.1573C10.8898 20.1495 10.3661 19.8603 10.1941 19.5945C10.0378 19.3443 10.0378 19.2818 10.0847 17.1399L10.1394 14.9354L8.84172 13.4032C8.13036 12.5512 7.48934 11.7616 7.42681 11.6365C7.19229 11.1831 7.35645 10.5343 7.77076 10.2373C7.88021 10.1513 8.85736 9.83858 9.92832 9.52589C11.0071 9.2132 11.9373 8.90833 12.0077 8.83016C12.0781 8.7598 12.5627 8.00153 13.0943 7.14164C14.1418 5.42967 14.2747 5.25769 14.6186 5.10135C14.9235 4.96063 15.4785 5.00754 15.7365 5.20297Z" fill="#18384A"/>
<path d="M14.3917 9.49462C13.7273 10.5812 13.4146 10.9486 13.1175 11.0424C13.0393 11.0659 12.4687 11.23 11.8433 11.4098C11.2179 11.5896 10.6864 11.7616 10.6551 11.7851C10.616 11.8163 10.999 12.3245 11.4915 12.9107C11.984 13.497 12.4061 14.0677 12.4374 14.1693C12.4609 14.2709 12.4687 14.9901 12.4531 15.7719C12.4374 16.5536 12.4374 17.1868 12.4531 17.1868C12.4687 17.1868 13.0393 16.9601 13.7273 16.6786C14.595 16.3269 15.0718 16.1705 15.3063 16.1705C15.5096 16.1705 16.1115 16.3503 16.8854 16.6317C17.5733 16.8819 18.144 17.0851 18.1518 17.0773C18.1596 17.0695 18.1205 16.4363 18.058 15.6624C17.9251 13.9661 17.8938 14.0677 19.0273 12.6137L19.8247 11.6053L18.4176 11.2457C17.245 10.9486 16.9714 10.847 16.776 10.6672C16.6431 10.5421 16.2288 9.97147 15.8535 9.40082C15.4783 8.82234 15.15 8.35331 15.1265 8.35331C15.1031 8.35331 14.7748 8.86925 14.3917 9.49462Z" fill="#18384A"/>
<path d="M7.3091 31.2343C4.11967 31.7424 1.64162 32.6649 0.680098 33.7124C0.156344 34.2752 0 34.6426 0 35.3227C0 35.8308 0.0312689 35.9637 0.234517 36.3311C0.492485 36.808 0.977152 37.2536 1.68852 37.6835C5.10464 39.7551 14.5791 40.6072 22.24 39.5362C27.7511 38.7701 30.6357 37.2067 30.4246 35.1195C30.2761 33.6576 28.6423 32.532 25.5154 31.7346C24.5461 31.4845 22.0133 31.0232 21.6302 31.0232C21.5833 31.0232 20.7469 32.2115 19.7776 33.6576C18.6441 35.3462 17.8545 36.4406 17.5887 36.6985C16.252 37.9884 14.157 37.9884 12.8202 36.6985C12.5466 36.4406 11.7649 35.354 10.6314 33.6576C9.18523 31.5001 8.83346 31.0232 8.69275 31.0311C8.59894 31.0311 7.97356 31.1249 7.3091 31.2343Z" fill="#18384A"/>
</svg> */}




            </div>
            <div className="ConferenceCoChairmen_comp_container_title">
              <h3>Contact Info</h3>
            </div>
        </div>
        <div  className="conference_venu_text">
         <ul>
         <li>For any conference related queries, please write to: <a style={{textDecoration:"underline"}} href="mailto:icomc24@iitk.ac.in">icomc24@iitk.ac.in</a> or call us on <a style={{textDecoration:"underline"}}  href="tel:7607414178">7607414178</a> </li>
          <li>For Accommodation, Travel and Tours, and any other conference related logistics, please write to: <a style={{textDecoration:"underline"}} href="mailto:icomc2024@delve-serwiz.com">icomc2024@delve-serwiz.com</a></li>
        <li>For VISA-related queries, please write to <a style={{textDecoration:"underline"}} href="mailto:icomcvisainformation@gmail.com">icomcvisainformation@gmail.com</a></li>
        </ul>
        </div>
        

  </div>
}









const Contact = ({setNavigation}) => {

    useEffect(()=>{
      setNavigation(15)
        localStorage.setItem('icomcNavigation',15)
    },[])
    
  return (<div className="organization_page">
  <div className="organization_page_container">
    <h1>Contact</h1>
      <ContactComp/>
    </div>
    </div> 
  )
}

export default Contact