import React, { useEffect } from 'react'
import "./Tour.css"
import "../Organization/Organization.css"
import "../Registration/Registration.css"
import "../ConferenceVenu/ConferenceVenu.css"




const TourComp = () => {
  return <div style={{
    marginTop:"2.7rem"
}}  className="ConferenceCoChairmen_comp_container">
       
       <div className="ConferenceCoChairmen_comp_container_header">
            <div style={{
              padding:"1rem",
              // paddingInline:"1.4rem",
            }} className="ConferenceCoChairmen_comp_container_logo">


<img style={{width:"42px"}} src="https://cdn-icons-png.flaticon.com/512/854/854894.png" alt="" />






            </div>
            <div className="ConferenceCoChairmen_comp_container_title">
              <h3>Tours</h3>
            </div>
        </div>


        <div className="conference_venu_text">
        <p>The conference venue, Agra, is one of the most popular travel destinations in the world. Our conference guests can make the most of the opportunity by exploring some of the most remarkable and historically important places in and around Agra.  Apart from an excursion to the Taj Mahal (organized on the basis of payment made during registration), participants might like to explore other options for sightseeing tours.</p>
        </div>
         
         <ul>
          <li>Sightseeing tours will be organized for interested conference attendees, which would include visits to the Agra Fort (around 6.6 km away from the conference hotel) and Fatehpur Sikri which is a World Heritage Site (around 43 km from the conference hotel).</li>
          <li>Post conference tours can also be arranged to the holy twin cities of Mathura and Vrindavan. A day trip to Delhi to explore some of the most significant places in the national capital is another option.</li>
          <li>For any queries related to tours, please write to <a style={{textDecoration:"underline"}} href="mailto: icomc2024@delve-serwiz.com">icomc2024@delve-serwiz.com</a></li>
         </ul>
       
        
        

  </div>
}









const Tour = ({setNavigation}) => {

    useEffect(()=>{
      setNavigation(10)
        localStorage.setItem('icomcNavigation',10)
    },[])
    
  return (<div className="organization_page">
  <div className="organization_page_container">
    <h1>Tours</h1>
      <TourComp/>
    </div>
    </div> 
  )
}

export default Tour