import React from 'react';
import "./DynamicLoader.css"
const DynamicLoader = () => {
 

  return (<div className="containers">
  <div className="loader">
    <div className="loader--dot"></div>
    <div className="loader--dot"></div>
    <div className="loader--dot"></div>
    <div className="loader--dot"></div>
    <div className="loader--dot"></div>
    <div className="loader--dot"></div>
    <div className="loader--text"></div>
  </div>
</div>
  );
};

export default DynamicLoader;
