import React from 'react'
import './Header.css';
import { LOGO } from '../SVG/Dates';
import { useNavigate } from 'react-router';

const Header = () => {
  const navigate = useNavigate()
  return (
    <div onClick={()=>{
      localStorage.setItem('icomcNavigation',0)
          window.location.href="/"
    }} className="header_container">    
        
     <img className='center' src= {LOGO} alt="" />

    </div>
  )
}

export default Header
