import React, { useEffect, useState } from 'react';
import { RiMenu3Line, RiCloseLine } from 'react-icons/ri';
import './NavBar.css';
import { arrow } from '../SVG/Dates';
import Header from '../Header/Header';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'react-scroll';
import { NavLink } from 'react-router-dom';



const Nav_Bar = ({navigation,setNavigation,toggleMenu,setToggleMenu}) => {



   useEffect(()=>{
    if(localStorage.getItem('icomcNavigation'))
    {
          setNavigation(JSON.parse(localStorage.getItem('icomcNavigation')))
    }
   },[])
    
   const location = useLocation()
console.log(location.pathname)



  return (
    <div onClick={(event)=>{
      event.stopPropagation()
      //setToggleMenu(false);
    }} className="LMS__navbar">
         <Header/>

      <div className="LMS__navbar-links">
        <div className="LMS__navbar-links_container">
          {
            ["Welcome","Organization",
         "Speakers","Registration","Programme",
         "Accommodation","Social Programme","Abstract",
       "Conference Venue","Useful Information", "Tours","Visa","Partners & Exhibitors",
         "Conference Schedule","Registration Status","Participation Certificates"
       ,"Contact"].map((item,index)=>{
        return  <a className={item.toLowerCase().replace(/\s/g, "")} href={`${item == "Welcome" ? "/" :  item === "Abstract"  ? "abstracts" :  item.toLowerCase().replace(/\s/g, "")}`} onClick={()=>{
          setNavigation(index)

      localStorage.setItem('icomcNavigation',index)
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth", // You can change this to "auto" for instant scrolling
      // });
        }} id={location.pathname == (item == "Welcome" ? "/" :  item === "Abstract"  ? "/abstracts" :  "/"+item.toLowerCase().replace(/\s/g, "")) ? "active" : ""} ><div style={{
          display:"flex",
          alignItems: "center",
          justifyContent: "center",
          columnGap:"0.5rem"
        }}>{item} </div> </a>
      })
          }
         
         
        </div>
      </div>
      
      <div className="LMS__navbar-menu">
        {toggleMenu
          ? <RiCloseLine color="black" size={27} onClick={(event) => {
            event.stopPropagation();
            setToggleMenu(false)}} />
          : <RiMenu3Line color="black" size={27} onClick={(event) => {
            event.stopPropagation()
            setToggleMenu(true)}} />}
        {toggleMenu && (
        <div className="LMS__navbar-menu_container scale-up-center">
          <div className="LMS__navbar-menu_container-links">
          {
            ["Welcome","Organization",
         "Speakers","Registration","Programme",
         "Accommodation","Social Programme","Abstract",
       "Conference Venue","Useful Information", "Tours","Visa","Partners & Exhibitors",
         "Conference Schedule","Registration Status",
         "Participation Certificates",
      "Contact"].map((item,index)=>{
        return  <a className={item.toLowerCase().replace(/\s/g, "")} href={`${item == "Welcome" ? "/" :  item === "Abstract"  ? "abstracts" :  item.toLowerCase().replace(/\s/g, "")}`} onClick={()=>{
          setNavigation(index)

      localStorage.setItem('icomcNavigation',index)

          // navigate(`${item == "Welcome" ? "/" : item.toLowerCase().replace(/\s/g, "")}`)
        }} id={location.pathname == (item == "Welcome" ? "/" :  item === "Abstract"  ? "abstracts" :  "/"+item.toLowerCase().replace(/\s/g, "")) ? "active" : ""} >{ <div>{item}</div> }</a>
      })
          }
          </div>
          
        </div>
        )}
      </div>
    </div>
  );
};

export default Nav_Bar;





