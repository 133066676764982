import React, { useEffect } from 'react'
import "./UserfullInfo.css"
import "../Organization/Organization.css"
import "../Registration/Registration.css"
import "../ConferenceVenu/ConferenceVenu.css"




const UsefullInfoComp = () => {
  return <div style={{
    marginTop:"2.7rem"
}}  className="ConferenceCoChairmen_comp_container">
        <div className="ConferenceCoChairmen_comp_container_header">
            <div style={{
              padding:"0.8rem",
              // paddingInline:"1.4rem",
            }} className="ConferenceCoChairmen_comp_container_logo">


<img style={{width:"40px"}} src="texi.png" alt="" />






            </div>
            <div className="ConferenceCoChairmen_comp_container_title">
              <h3>Taxi Service</h3>
            </div>
        </div>
        <div style={{marginTop:"1rem"}} className="conference_venu_text">
        <p>Taxi service from New Delhi to Agra Conference Venue (around 240 km), both onward and return journeys, is available on payment basis.
          <br />
           Please contact: <a style={{textDecoration:"underline"}} href="mailto: icomc2024@delve-serwiz.com"> icomc2024@delve-serwiz.com</a></p>
        </div>

        <div style={{marginTop:"3rem"}} className="ConferenceCoChairmen_comp_container_header">
            <div style={{
              padding:"0.8rem",
              // paddingInline:"1.4rem",
              
            }} className="ConferenceCoChairmen_comp_container_logo">


<img style={{width:"40px"}} src="hotel.png" alt="" />






            </div>
            <div className="ConferenceCoChairmen_comp_container_title">
              <h3>Transit Hotel Options</h3>
            </div>
        </div>
        <div className="conference_venu_text">
        <p>If you need a short stay at New Delhi before heading to Agra, several Hotel options are available in Aerocity area close to New Delhi International Airport (Terminal 3).</p>
        </div>
        

  </div>
}









const UserfullInfo = ({setNavigation}) => {

    useEffect(()=>{
      setNavigation(9)
        localStorage.setItem('icomcNavigation',9)
    },[])
    
  return (<div className="organization_page">
  <div className="organization_page_container">
    <h1>Useful Information</h1>
      <UsefullInfoComp/>
    </div>
    </div> 
  )
}

export default UserfullInfo